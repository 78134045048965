import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-fd6d064a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "small-menu"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  key: 0,
  class: "overlay"
};
const _hoisted_4 = ["onClick"];
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
export default {
  __name: 'small-menu',
  props: {
    menuList: {
      type: Array,
      default: () => []
    }
  },
  setup(__props) {
    // 是否显示菜单
    const isOpenMenu = ref(false);

    // 图标
    const getIcon = computed(() => {
      return isOpenMenu.value ? require('@/assets/images/glob/icon_cancel.png') : require('@/assets/images/glob/icon_menu.png');
    });
    const changeShow = () => {
      isOpenMenu.value = !isOpenMenu.value;
    };

    // 禁止滚动
    watch(isOpenMenu, newValue => {
      if (newValue) {
        document.documentElement.style.width = '100vw';
        document.documentElement.style.height = '100vh';
        document.documentElement.style.overflow = 'hidden';
      } else {
        document.documentElement.style.width = 'auto';
        document.documentElement.style.height = 'auto';
        document.documentElement.style.overflow = 'auto';
      }
    });
    const router = useRouter();
    const toPage = path => {
      isOpenMenu.value = false;
      router.push(path);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
        class: "menu-icon",
        src: getIcon.value,
        onClick: changeShow
      }, null, 8, _hoisted_2)]), isOpenMenu.value ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.menuList, item => {
        return _openBlock(), _createElementBlock("div", {
          key: item.name,
          class: "item",
          onClick: $event => toPage(item.path)
        }, _toDisplayString(item.name), 9, _hoisted_4);
      }), 128))])) : _createCommentVNode("", true)], 64);
    };
  }
};