import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onUnmounted } from 'vue';
import _ from 'lodash';
import flexible from './utils/lib-flexible';
import layout from './layout/index';

// 响应式布局
// 在页面进行挂载的时候去执行flexible函数

export default {
  __name: 'App',
  setup(__props) {
    flexible(window, document);

    // 监听窗口改变大小
    const _flexible = _.throttle(() => {
      flexible(window, document);
    }, 100);
    window.addEventListener('resize', _flexible);

    // 卸载取消监听
    onUnmounted(() => {
      // 页面挂载取消监听
      window.removeEventListener('resize', _flexible);
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_unref(layout), null, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      });
    };
  }
};