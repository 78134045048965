export const getFontSize = () => {
  let screenRatioByDesign = 16 / 9
  var screenRatio = document.documentElement.clientWidth / document.documentElement.clientHeight;
  var fontSize = (
    screenRatio > screenRatioByDesign ?
    (screenRatioByDesign / screenRatio) :
    1
  ) * document.documentElement.clientWidth / 10;
  return fontSize
}
export default function flexible(window, document) {
  let fontSize = getFontSize()
  document.documentElement.style.fontSize = fontSize.toFixed(3) + "px";
}