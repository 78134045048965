import { createRouter, createWebHashHistory } from 'vue-router'

const router = createRouter({
  history: createWebHashHistory(),
  // 映射关系: path -> component
  routes: [
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/home",
      component: () => import("@/views/home/index.vue"),
      meta: {
        title: '关于我们'
      }
    },
    {
      path: "/about",
      component: () => import("@/views/about/index.vue"),
      meta: {
        title: '关于我们'
      }
    },
    {
      path: "/honors",
      component: () => import("@/views/honors/index.vue"),
      meta: {
        title: '资质荣誉'
      }
    },
    {
      path: "/afterSales",
      component: () => import("@/views/afterSales/index.vue"),
      meta: {
        title: '专业售后'
      }
    },
    {
      path: "/contact",
      component: () => import("@/views/contact/index.vue"),
      meta: {
        title: '联系我们'
      }
    },
    {
      path: "/programme",
      component: () => import("@/views/programme/index.vue"),
      meta: {
        title: '解决方案'
      }
    },
  ]
})

export default router
