import { createVNode as _createVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import sHeader from './components/s-header.vue';
import sFooter from './components/s-footer.vue';
export default {
  __name: 'index',
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(sHeader), _renderSlot(_ctx.$slots, "default"), _createVNode(sFooter)], 64);
    };
  }
};